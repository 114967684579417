import React from "react"
import { domain } from "../utils";
import Markdown from "markdown-to-jsx"
import Link from "./link";
import classNames from "classnames";
import { CustomHr } from "./CustomHr";

const ServiceCard = ({ service, language, showHeading, shouldNotLazyLoad, imageBorderRadiusForServices, websiteFontColor}) => {
    const media = service?.media; 
    const body = service?.body;
    const link = service?.link;
    
    if(service) {
      return (
          <>
              <img
                src={`${media && media.media && media.media.url}`}
                style={{borderRadius: imageBorderRadiusForServices}}
                alt="placeholder"
                {...(!shouldNotLazyLoad && { loading: 'lazy' })}            
              />
              {showHeading && (
                <h1 className="heading-outline">{service?.heading}</h1>
              )}
              {body && (
                  <div className="rich-text rich-text-nolink">
                    <Markdown options={{
                      overrides: {a: {
                        component: Link
                      },                           
                      hr: { 
                          component: CustomHr,
                          props: {
                            borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                          }
                        }  
                      }
                    }}>
                    {body.richText || ''}
                  </Markdown>
                  </div>
              )}
              {link && link.length > 0 && (
                <>
                  {link.length > 1 ? (
                    <ul className="list list-border list-arrowed list-arrowed">
                      {link.map(({ label, path }, index) => (
                      <li key={index} className="list-border__item text-decoration-none">
                          <Link to={`/${language}/${path}`}>{label}</Link>
                      </li>
                      ))}
                    </ul>
                  ) : (
                    <Link className="button" to={`/${language}/${link[0].path}`}>
                      <span className="button__text">{link[0].label}</span>
                    </Link>
                  )}
                </>
              )}
          </>
      )
    } 
    else {
      return null;
    }

}

export default ServiceCard
