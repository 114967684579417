import React, { useContext } from "react"

import Layout from "../components/wrappers/layout"
import SiteHeaderAlt from "../components/wrappers/site-header-alt"
import SEO from "../components/seo"
import Link from "../components/link";
import { domain } from "../utils"
import Header from '../components/header';
import { graphql } from 'gatsby';
import Markdown from 'markdown-to-jsx';
import ServiceCard from "../components/service-card";
import Logos from "../components/logos";
import { getSinglePage } from "../utils";
import { GlobalStateContext } from "../context/GlobalContextProvider";
import { CustomHr } from "../components/CustomHr";

const Services = props => {
  const { servicesOverview, footers, headers, general } = props.data.strapi;
  const { copyright } = servicesOverview;
  const { generalBackgroundColorDarkMode, generalBackgroundColorLightMode, clientLogosColorDarkMode, clientLogosColorLightMode , fontColorDarkMode, fontColorLightMode } = general; 
  const language = props.pageContext.language;
  const header = headers && headers.find((header)=>  header.language === language ); 
  const footer = footers && footers.find((footer)=>  footer.language === language ) || {}; 
  const { thumbrows, services, logos, seo, darkTheme } = servicesOverview;
  const state = useContext(GlobalStateContext);
  const darkMode = state.darkModePreference === 'moon'; 
  // getting  page first H1 to set as meta title and description if not set in strapi .
  let pageFirstHeading = services.header && (services.header.supheading+" " + services.header.heading)
  const sectionBackgroundColor = darkMode ? generalBackgroundColorDarkMode : generalBackgroundColorLightMode; 

  const seoTitle = seo && seo.metaData.metaTitle || pageFirstHeading|| "Services";
  const seoDescription = seo && seo.metaData.metaDescription || pageFirstHeading || "Services";
  const seoKeywords = seo && seo.metaData?.keywords;

  const allPages = props.data?.allSitePage?.nodes; 
  const webpages =  servicesOverview && servicesOverview.webpages || {}; 
  const redirectTo = getSinglePage(webpages, allPages); 
  const logosBgColor = darkMode ? clientLogosColorDarkMode : clientLogosColorLightMode; 
  const websiteFontColor = darkMode ? fontColorDarkMode : fontColorLightMode; 

  if (copyright) {
    footer.copyright = copyright;
  }

  return (
    <Layout header={header} footer={footer} language={language} redirectTo={redirectTo}>
      <SEO
        websiteFontColor={websiteFontColor} 
        template="services" 
        title={seoTitle} 
        description={seoDescription} 
        keywords={seoKeywords} 
        darkTheme={darkTheme}  
        darkMode={darkMode}
        websiteBackgroundColor={sectionBackgroundColor}
      />
      <SiteHeaderAlt header={header} redirectTo={redirectTo}>
        {services && (
          <section className="section services-categories section-small-margin" style={{backgroundColor: sectionBackgroundColor}}>
            {services.header && (
              <Header heading={services.header.heading} supheading={services.header.supheading} />
            )}

            {services.services && services.services.length > 0 && (
              <div className="container services-categories__list">
                <div className="row">
                  {services.services.map((service, index) => {
                    return (
                      <article className="services-categories__item col-tw-6 offset-lg-1 col-lg-5">
                        <ServiceCard service={service} showHeading={true} language={language} websiteFontColor={websiteFontColor} />
                      </article>
                    );
                  })}
                </div>
              </div>
            )}
          </section>
        )}
        
        {thumbrows && (
          <section className="section thumb-rows" style={{backgroundColor: sectionBackgroundColor}}>
            {thumbrows.header && (
              <Header heading={thumbrows.header.heading} supheading={thumbrows.header.supheading} />
            )}
            
            {thumbrows.thumbrow && thumbrows.thumbrow.length > 0 && (
              <div className="thumb-rows__list container">
                {thumbrows.thumbrow.map((thumbrow, index) => {
                  return (
                    <div className="thumb-rows__item row">
                      <div className="thumb-rows__thumb col-tw-3 offset-lg-1 col-lg-2">
                        {thumbrow.media && (
                          <img src={thumbrow.media.url} alt={thumbrow.media.alternativeText} loading="lazy" />
                        )}
                      </div>
                      <div className="thumb-rows__body rich-text col-tw-9 col-lg-8">
                        <b>{thumbrow.heading}</b>
                        <div>                  
                          <Markdown options={{
                            overrides: {a: {
                              component: Link
                            },                           
                            hr: { 
                                component: CustomHr,
                                props: {
                                  borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                                }
                              }  
                            }
                          }}>
                            {thumbrow.body}
                            </Markdown>
                         </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </section>
        )}

        {logos && (
          <Logos component={logos} sectionBackgroundColor={sectionBackgroundColor} logosBgColor={logosBgColor} />
        )}
      </SiteHeaderAlt>
    </Layout>
  )
}

export const pageQuery = graphql`
  query Services($id: ID!, $language: String!) {
    allSitePage {
      nodes {
        path
      }
    }
    strapi {
      general {
        generalBackgroundColorDarkMode
        generalBackgroundColorLightMode
        clientLogosColorDarkMode
        clientLogosColorLightMode
        fontColorDarkMode
        fontColorLightMode
      }
      headers(where: {language: $language}) {
        ...SiteHeader
      }
      footers(where: { language: $language }) {
        ...SiteFooter
      }
      servicesOverview(id: $id) {
        copyright
        darkTheme 
         webpages {
            about_us {
              language
            }
            contact {
              language
            }
            blogs_overview {
              language
            }
            cases_overview {
              language
            }
            case_study {
              slug
              language
            }
            careers_overview {
              language
            }	
            services_overview {
              language
            }
            blog {
              language
              slug
            }
            career {
              language
              slug
            }
            homepage {
              slug
              language
            }
            service_design {
              slug
              language
            }
            service_technical {
              slug
              language
            }
            service_casey_new {
              slug
              language
            }
            service_casey {
              slug
              language
            }
            plainpage {
              language
              slug
            }
          }
        seo {
          metaData {
            metaTitle
            metaDescription
            keywords
          }
        }
        thumbrows {
          header {
            supheading
            heading
          }
          thumbrow {
            media {
              url
              alternativeText
              mime
              ext
            }
            heading
            body 
          }
        }
        services {
          header {
            supheading
            heading
          }
          services {
            heading
            media {
              media {
                url 
              }
            }
            body {
              richText
            }
            link {
              label
              path
            }
          }
        }
        logos {
          header {
            supheading
            heading
          }
          logos {
            url
            alternativeText
            mime
            ext
          }
          affix
        }
      }
    }
    thumbrow: file(relativePath: {eq: "placeholder.jpg"}) {
      childImageSharp {
        fixed(width: 239, height: 239, cropFocus: CENTER) {
          srcSetWebp
          src
        }
      }
    }
    category: file(relativePath: {eq: "placeholder.jpg"}) {
      childImageSharp {
        fixed(width: 655, height: 369, cropFocus: CENTER) {
          srcSetWebp
          src
        }
      }
    }
    logo: file(relativePath: {eq: "placeholder.jpg"}) {
      childImageSharp {
        fixed(width: 155, height: 105, cropFocus: CENTER) {
          srcSetWebp
          src
        }
      }
    }
  }
`

export default Services
